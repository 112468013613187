import * as React from "react"
import { Link } from "gatsby"

import Container from "../components/molecules/Container"
import Header from "../components/organisms/Header"
import Footer from "../components/organisms/Footer"
import Title from '../components/atoms/Title'

// markup
const NotFoundPage = () => {
  return (
    <>
      <Header />
      <Container>
          <main>
            <Title level='1'>Page not found</Title>
            <div style={{maxWidth: '600px', margin: '0 auto', textAlign: 'center'}}>
              <p>We couldn’t find what you were looking for.</p>
              <Link to="/">Go home</Link>.
            </div>
          </main>
      </Container>
      <Footer />
    </>
  )
}

export default NotFoundPage
